<template>
  <PSection pubg-key-visual-section-23s3>
    <div class="inner-holder">
      <div class="header">
        <h2>TO BE A NEXT PRO</h2>
        <h1>PUBG 이스포츠 아마추어 대회</h1>
        <p class="logo">PUBG LVUP SHOWDOWN</p>
      </div>
      <div class="info">
        <dl>
          <dt>예선 &amp; 본선</dt>
          <dd>8월 21일(월) ~ 9월 5일(화)</dd>
        </dl>
        <dl>
          <dt>결선</dt>
          <dd>9월 11일(월) ~ 9월 12일(화)</dd>
        </dl>
      </div>
      <div class="btn-go-apply">
        <button @click="goApply">참가 신청 바로가기</button>
      </div>
    </div>
    <div class="btn-down">
      <button @click="toNextSection">Scroll Down
        <svg-angle />
      </button>
    </div>
  </PSection>
</template>

<script>
import SvgAngle from '@shared/graphics/svg-angle.vue';
import gtag from 'shared/mixins/gtag';

export default {
  name: 'PubgKeyVisualSection',
  mixins: [gtag],
  components: {
    SvgAngle,
  },
  methods: {
    toNextSection() {
      const secondSection = this.$el.nextSibling;
      const targetScrollOffset = secondSection.offsetTop - (document.querySelector('header').clientHeight || 0);
      this.$scroll.scrollMove(targetScrollOffset, 1000);
    },
    goApply() {
      this.trackEvent(`${this.$route.meta.leagueCode}_landing_challenge_01`, 'click', 'PUBG');
      this.$router.push(`/b/pubg${this.$route.params.league ? `/${this.$route.params.league}` : ''}?page=arena`);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
@pls202303: '/img/pages/pls202303';

[pubg-key-visual-section-23s3] { .rel; .flex-center; .h(calc(100vh - 140px)); .tc; .cover('@{pls202303}/bg_intro_landing.jpg'); .bg-xc; .crop;
  .intro-up(1, {
    .btn-down, .header p.logo { .o(1); }
    .header h2, .header h1,
    .info, .btn-go-apply { .t-y(0); .o(1); }
    .header p.logo { transform+: scale(1); }
    &:before { .t-s(1); clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%); }
    &:after { .o(.3); clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%); }
  });
  &:before,
  &:after { .cnt; .abs; .t(0); .r(0); .b(0); .l(0); .z(0); pointer-events: none; }
  &:before { .cover('@{pls202303}/bg_intro_landing.jpg'); .bg-xc; .t-s(1.1); .tr-t(2.5s);
    transition+: clip-path .35s 1.25s ease-out;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  &:after { .bgc(rgba(255, 255, 255, .1)); backdrop-filter: blur(10px); .o(1); .tr-o(3s, .5s);
    transition+: clip-path .35s 1s ease-out;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .inner-holder { .rel; .z(1); .mt(-30); }
  .header {
    h2 { .wh(154, 24); .mh-c;.fs(0, 0); .contain('@{pls202303}/to-be-a-next-pro.svg'); .o(0); .t-y(30); .tr-to(.5s, .3s, .7s); }
    h1 { .mt(10); .font-g; .bold; .fs(26); .ls(-1px); .o(0); .t-y(30); .tr-to(.5s, .3s, .8s); }
    p.logo { .mt(20); .wh(304, 106); .fs(0, 0); .mh-c; .no-repeat('@{pubg-intro}/bg_key_logo.svg'); .o(.3); .tr-o(1.5s, 1s); .tr-t(2s, 0s);
      transform+: scale(.9); transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }
  }
  .info { .mt(32); .inline-flex; flex-direction: column; .o(0); .t-y(75); .tr-to(.5s, .3s, .9s);
    dl { .flex;
      + dl { .mt(8);
        dt { .bgc(#d13639); }
      }
    }
    dt, dd { .flex; .flex-ai; }
    dt { .w(80); .flex-jc; .bold; .fs(14, 32); .bgc(#414145); .br(20); }
    dd { .ml(8); .min-w(183); .medium; .fs(16); }
  }
  .btn-go-apply { .mt(44); filter: grayscale(0%); .o(0); .t-y(75); .tr-to(.5s, .3s, 1s);
    animation: loop-grayscale 2s infinite; animation-delay: 2s;
    &:hover { filter: grayscale(0); animation: none; }
    button { .wh(359, 84); .no-repeat('@{pubg-intro}/btn_goto.png'); .fs(0, 0); .c(transparent); }
  }
  .btn-down { animation: wr-bounce 1.5s infinite; .o(1); .abs; .z(1); .l(0); .r(0); .b(36); .tc; .w(100%); .t-y(75); .tr-to(.3s, .3s, 1.5s);
    > button { .c(#fff); .fs(12, 16); .ls(0); .ib;
      > svg { .wh(26, 15); .stroke(#fff); .block; .mh-c; .mt(7);}
    }
  }
  @media (max-height: 667px) {
    // 디자인 협의후 여백 조정
    .info { .mt(27); }
    .btn-go-apply { .mt(27); }
    .btn-down { .b(27);}
  }

  @media (@tl-up) {
    .mt(0); .h(960); .bg-s(auto, auto);
    .inner-holder { .mt(190); }
    .header {
      h2 { .wh(256, 40); }
      h1 { .mt(16); .fs(48); }
      p.logo { .mt(24); .wh(484, 170); .bg('@{pubg-intro}/tl/bg_key_logo.svg'); }
    }

    .info { flex-direction: row;
      dl { .flex;
        + dl { .mt(0); .ml(40);
          dt { .min-w(70); }
        }
      }
      dt { .w(auto); .ph(10); .flex-jc; .bold; .fs(16, 36); .bgc(#414145); .br(20); }
      dd { .ml(12); .min-w(inherit); }
    }
    .btn-go-apply { .mt(24);
      button { .wh(556, 153); .bg('@{pubg-intro}/tl/btn_goto.png'); }
    }
    .btn-down { .b(60);
      > button { .fs(16);}
    }
  }

  @media (@ds-up) {
  }
}
</style>
