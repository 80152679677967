<template>
  <div pubg-intro-23s3>
    <KeyVisualSection id="main-visual" />
    <PubgRewardMatchSection />
    <ParticipationRuleSection id="participation-rules" />
    <PSection class="quick-menu-holder">
      <QuickMenu v-prx:fixed
        v-if="quickMenu.menus.length"
        :button="quickMenu.button"
        :menus="quickMenu.menus"
        @button-click="discordConnect"
      />
    </PSection>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import KeyVisualSection from '@/views/components/pls_2023_3/landing/KeyVisualSection.vue';
import PubgRewardMatchSection from '@/views/components/pls_2023_3/landing/PubgRewardMatchSection.vue';
import QuickMenu from '@/views/components/pls_2023_3/landing/QuickMenu.vue';
import ParticipationRuleSection from '@/views/components/pls_2023_3/landing/ParticipationRuleSection.vue';

export default {
  name: 'PubgIntro',
  mixins: [gtag],
  components: {
    KeyVisualSection,
    PubgRewardMatchSection,
    ParticipationRuleSection,
    QuickMenu,
  },
  data() {
    return {
      quickMenu: {
        button: { img: '/img/pages/icon/discord.svg', text: '팀원 찾기' },
        menus: [
          { domId: 'main-visual', name: '대회 소개' },
          { domId: 'basic-rewards', name: '대회 보상' },
          { domId: 'match-rules', name: '대회 방식' },
          { domId: 'participation-rules', name: '참고 사항' },
        ],
      },
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    discordConnect() {
      this.trackEvent(`${this.$route.meta.leagueCode}_landing_discord_btn`, 'click', `${this.upRouteGameId}`);
      window.open('https://discord.gg/VCb8BQBNVw');
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[pubg-intro-23s3] { .pt(0); .bgc(#1a1d22); .c(#fff);
  .quick-menu-holder { .fix; .z(10); .lt(0, 0);}
  @media (@tl-up) {
    .pt(0);
  }
  @media (@ds-up) {
  }
}
</style>
