<template>
  <PSection pubg-reward-match-section-23s3>
    <div class="inner-holder">
      <PubgRewardGroup id="basic-rewards" data-offset="400" />
      <PubgMatchGroup id="match-rules" />
    </div>
  </PSection>
</template>

<script>
import PubgRewardGroup from './PubgRewardGroup.vue';
import PubgMatchGroup from './PubgMatchGroup.vue';

export default {
  name: 'PubgRewardMatchSection',
  components: {
    PubgRewardGroup,
    PubgMatchGroup,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[pubg-reward-match-section-23s3] {
  @media (@tl-up) {
  }
  @media (@ds-up) {
  }
}
</style>
