<template>
  <PSection do-participation-rule-section-23s3 theme="white" id="participation-rules">
    <div class="holder rules">
      <h4>참여 시 꼭 확인하세요.</h4>
      <div class="rules-content">
        <article>
          <h5>대회 일정 참고사항</h5>
          <ul>
            <li>대회 기간: 8월 21일(월)부터 9월 12일(화)까지 매주 월, 화 진행</li>
            <li>참가 신청 오픈:
              <ul>
                <li>1주차: 8월 4일(금) ~ 8월 18일(금)</li>
                <li>2주차: 8월 11일(금) ~ 8월 25일(금)</li>
                <li>3주차: 8월 18일(금) ~ 9월 1일(금)</li>
                <li>* 참가 접수 기간 이후 로스터 및 PUBG 스팀 변경 불가</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li><b>예선: 8월 21일(월), 8월 28일(월), 9월 4일(월)</b></li>
            <li><b>본선: 8월 22일(화), 8월 29일(화), 9월 5일(월)</b></li>
            <li><b>결선: 9월 11일(월), 9월 12일(화)</b></li>
          </ul>
        </article>
        <article>
          <h5>참여 자격</h5>
          <ul>
            <li>대회 시작 시간 기준 한시간 전부터 30분 간 체크인 등록 (로스터 상 리더 가능)</li>
            <li>대회 참가자들은 LVUP PUBG 디스코드 채널에 참여해야 합니다. (링크 참고 : <a href="https://discord.gg/VCb8BQBNVw" target="_blank">https://discord.gg/VCb8BQBNVw</a>)</li>
            <li>대회 참가자들은 LVUP.GG 홈페이지 프로필에 PUBG Steam 닉네임이 일치해야 합니다. (닉네임 변경한 경우, 프로필 수정->새로고침)</li>
            <li>반드시 리플레이 기능을 활성화해야 합니다.</li>
            <li>2023 년도 배틀그라운드 공식 프로팀의 대표자 및 계약 중인 선수 등 관계자는 PLS 참가 불가능</li>
            <li>참가 접수 마감 후 로스터 변경 불가</li>
            <li>만 18세 미만 선수 대회 참가 동의서 제출 (필수)</li>
          </ul>
        </article>
        <article>
          <h5>예선 진행 방식</h5>
          <ul>
            <li>FPP 스쿼드 진행</li>
            <li>라이브 서버 / 온라인</li>
          </ul>
          <h6>참가 팀 수에 따른 그룹 분배</h6>
          <ul>
            <li>그룹별 팀 수 분할 배정, 그룹별 최대 17팀</li>
            <li>주최의 합리적인 판단에 따라 조 편성 및 본선 진출 기준 안내</li>
            <li>S.U.P.E.R(Point Rule) 규정에 따라 순위 산정</li>
            <li>예선전 상위 16팀 본선 진출</li>
            <li>그룹 수에 따라 본선 진출팀 균등 배분 후 차순위간 순위 비교로 본선 진출</li>
          </ul>
        </article>
        <article>
          <h5>본선 진행 방식</h5>
          <ul>
            <li>FPP 스쿼드 진행</li>
            <li>이스포츠 서버 / 온라인</li>
          </ul>
          <h6>우승 팀 선발 방식</h6>
          <ul>
            <li>S.U.P.E.R(POINT RULE) 규정에 따라 순위 산정</li>
            <li>주차별 본선 1위 3팀 + 2023 게임의 민족 플레이어 상위 2팀 + PLS Season 2 상위 4팀 + 본선 누적 포인트 상위 7팀 = 총 16팀 결선 진출</li>
            <li>결선 진출팀은 대회 진행 간 변경 될 수 있습니다.</li>
            <li>
              결선 진출 확정팀이 다시 우승하는 경우, 해당 주차의 진출권은 시즌 누적 순위 상위팀으로 이월<br> (EX: 1주차 경기 우승팀이 3주차에 우승하는 경우, 3주차 우승 진출권 → 시즌 누적 순위 상위팀 13팀에서 14팀으로 증가)
            </li>
          </ul>
        </article>
        <article>
          <h5>결선 진행 방식</h5>
          <ul>
            <li>FPP 스쿼드 진행</li>
            <li>이스포츠 서버 / 온라인</li>
          </ul>
          <h6>결선 진출 16팀 참가</h6>
          <ul>
            <li>주차별 본선 1위 3팀 + 2023 게임의 민족 플레이어 상위 2팀 + PLS Season 2 상위 4팀 + 본선 누적 포인트 상위 7팀 = 총 16팀 결선 참가</li>
          </ul>
          <h6>우승 팀 선발 방식</h6>
          <ul>
            <li>2일간 10개 맵의 킬, 순위 포인트 합산으로 순위 산정</li>
            <li>S.U.P.E.R(POINT RULE) 규정에 따라 순위 산정</li>
            <li>모든 결선 방송은 PUBG 공식 채널 및 LVUP 공식 채널을 통해 중계됩니다</li>
            <li>배틀그라운드 이스포츠(유튜브) / PUBGKOREA(트위치) / 레벨업 공식(아프리카TV)</li>
          </ul>
        </article>
      </div>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'ParticipationRuleSection',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[do-participation-rule-section-23s3] { .max-w(355); .mh-c; .pt(90); .pb(120);
  .rules { .-b(rgba(255, 255, 255, .1), 2);
    h4 { .pb(40); .font-g; .bold; .fs(20); .ls(-2); .c(rgba(255, 255, 255, .8)); .-b(rgba(255, 255, 255, .1), 2); .tc; }
    .rules-content { .p(40, 0, 32, 13); }
    article {
      > h5, > h6 { .font-spoqa; .c(rgba(255, 255, 255, .6)); }
      > h5 { .bold; .fs(16); }
      > h6 { .mt(12); .fs(11); }
      > ul { .mt(12);
        li { .font-spoqa; }
        > li { .rel; .pl(8); .fs(11, 16); .c(rgba(255, 255, 255, .4)); .ti(-10);
          a { text-transform: none; .c(rgba(255, 255, 255, .6));
            &:hover { .c(rgba(255, 255, 255, .8)); .underline; }
          }
          &:before { content: '• '; }
          > ul { .pl(10); }
        }
        &.none li { .pl(0);
          &:before { .cnt; }
        }
        ul + ul { .mt(8); }
      }
      + article { .mt(20); }
    }
  }
  @media (@tl-up) {
    .max-w(908); .pb(200);
    .rules {
      h4 { .fs(32); }
      .rules-content { .pb(40); }
      article {
        > h5 { .fs(24, 32); }
        > h6 { .mt(16); .fs(16); }
        > ul { .mt(12);
          > li { .pl(10); .fs(16, 24);
            > ul { .pl(15); }
          }
          ul + ul { .mt(10); }
        }
        + article { .mt(32); }
      }
    }
  }
  @media (@ds-up) {
    .max-w(1080);
    .rules {
      .rules-content { .pl(40); }
      article {
        > ul { .mt(10); }
        + article { .mt(34); }
      }
    }
  }
}
</style>
