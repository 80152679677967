<template>
  <div ref="quickMenu" quick-menu-23s3 :class="['quick-menu', moving ? 'moving' : '']">
    <div class="channel-go-btn">
      <ButtonRipple @click="$emit('button-click')">
        <img :src="button.img" :alt="button.text" />
        {{ button.text }}
      </ButtonRipple>
    </div>
    <div class="menu-li-wrap">
      <ul>
        <li v-for="menu in menus" :key="menu.domId" @click="scrollTo(`#${menu.domId}`, $event)">
          {{ menu.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import gtag from 'shared/mixins/gtag';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';

export default {
  name: 'QuickMenu',
  components: { ButtonRipple },
  mixins: [gtag],
  mounted() {
    this.initMoveY = this.initTerm;
    this.$nextTick(() => {
      this.scrollSpyHandler();
      window.addEventListener('scroll', this.scrollSpyHandler);
      window.addEventListener('resize', this.scrollSpyHandler);
      if (this.initPage > 0) this.scrollTo(`#${this.menus[this.initPage].domId}`);
    });
  },
  beforeDestroy() {
    this.$refs.quickMenu.style.display = 'none';
    window.removeEventListener('scroll', this.scrollSpyHandler);
    window.removeEventListener('resize', this.scrollSpyHandler);
  },
  data() {
    return {
      moving: false,
      initMove: true,
    };
  },
  props: {
    button: {
      type: Object,
      default: () => ({
        img: '/img/pages/icon/discord.svg',
        text: '대학 팀원 찾기',
      }),
    },
    menus: {
      type: Array,
      default: () => [],
    },
    initSubPage: String,
    initPage: { type: Number, default: 0 },
    initTerm: { type: Number, default: 0 },
  },
  methods: {
    scrollTo(idSelector) {
      let offsetTop;
      if (this.initMove && this.initSubPage) {
        offsetTop = document.querySelector(`${idSelector} .${this.initSubPage}`).getBoundingClientRect().top;
        this.initMove = false;
      } else {
        const el = document.querySelector(idSelector);
        offsetTop = el.offsetTop + +(el.dataset.offset ?? 0);
      }
      offsetTop -= (document.querySelector('header').clientHeight || 0);
      this.$scroll.scrollMove(offsetTop, 1000);
    },
    scrollSpyHandler() {
      if (window.innerWidth > 1024 && this.$refs.quickMenu) {
        this.moving = true;
        const sectionEls = this.menus.map(menu => document.querySelector(`#${menu.domId}`));
        const sectionOffsetTops = [];
        const lists = document.querySelectorAll('.menu-li-wrap>ul>li');
        let activeIndex = 0;

        sectionEls.forEach(el => {
          sectionOffsetTops.push(el.offsetTop - (document.querySelector('header').clientHeight || 0));
        });

        for (let i = 0; i < sectionOffsetTops.length; i++) {
          if (window.scrollY + 80 >= sectionOffsetTops[i]) {
            if (i + 1 >= sectionOffsetTops.length) {
              activeIndex = i;
            } else if (window.scrollY + 80 <= sectionOffsetTops[i + 1]) {
              activeIndex = i;
            }
          }
        }

        lists.forEach((el, idx) => {
          if (activeIndex === idx) {
            el.classList.add('active');
          } else {
            el.classList.remove('active');
          }
        });

        (_debounce(() => {
          this.moving = false;
        }, 1500))();
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[quick-menu-23s3] { .abs; z-index: 10; .w(128); .o(1); transition: opacity 0.3s; .hide;
  &.moving {
    will-change: transform, opacity;
  }
  .channel-go-btn { .mb(11);
    > button.btn-primary { .wh(100%, 92); font-family: 'gmarket', sans-serif; .fs(14); .medium; .c(#fff); background: linear-gradient(135deg, #2c81ff 0%, #0048b3 100%); .br(8);
      img { .block; .w(34); margin: 0 auto 8px;}
    }
  }

  .menu-li-wrap { .p(32, 0, 32, 22); .bgc(#fff); .-a(rgba(33, 49, 249, 0.5)); .br(8);

    > ul {
      > li { .rel; .mb(20); .fs(13); .semi-bold; .c(#000); .pointer; .font-spoqa; .medium;
        &:last-child { .mb(0); }
        &.active { .c(#df3a3a); .bold;
          &::before {.abs;.lt(-12, 8);z-index: 1;content: '';.block;.wh(4, 4);.bgc(#df3a3a);.br(50%);}
        }
      }
    }
  }

  @media (@tl-up) {
    .block; .lt(10, 150); .o(0.4);
    &.moving {.o(1);}
    &:hover {.o(1);}
  }

  @media (min-width: 1600px) {
    .lt(150, 150);
  }
}
</style>
