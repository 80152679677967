<template>
  <PSection tag="div" pubg-match-group-23s3>
    <h2>대회 방식</h2>
    <div class="article-group">
      <PItem tag="article" class="match-1">
        <h3>예선</h3>
        <div class="item">
          <p class="msg-1">그룹별 팀 수 분할 배정, 최대 17팀 5Match진행</p>
          <h4>매주 16팀씩 선발</h4>
          <p class="msg-2">* 그룹 수에 따라 본선 진출팀 균등 배분 후, <s>차순위간 순위 비교로 본선 진출</s></p>
          <div class="week-date">
            <dl v-for="(item, i) in weekDate1" :key="`week-1-${i}`" :class="`day-${i}`">
              <dt>
                <img :src="`/img/pages/pubg/intro/img_week_${i+1}.svg`" :alt="`WEEK ${i+1}`">
              </dt>
              <dd v-html="item"></dd>
            </dl>
          </div>
        </div>
      </PItem>
      <PItem tag="article" class="match-2">
        <h3>본선</h3>
        <div class="item">
          <p class="msg-1">예선 상위 16팀 본선 경기 5Match 진행</p>
          <h4>총 16팀 결선 진출</h4>
          <p class="msg-2">(주차별 본선 1위 3팀 + 2023 게임의 민족 플레이어 상위 2팀<br>
            + PLS Season 2 상위 4팀 + 본선 누적 포인트 상위 7팀)
          <div class="week-date">
            <dl v-for="(item, i) in weekDate2" :key="`week-2-${i}`" :class="`day-${i}`">
              <dt>
                <img :src="`/img/pages/pubg/intro/img_week_${i+1}.svg`" :alt="`WEEK ${i+1}`">
              </dt>
              <dd v-html="item"></dd>
            </dl>
          </div>
        </div>
      </PItem>
    </div>
    <div class="article-group">
      <PItem tag="article" class="match-3">
        <h3>결선</h3>
        <div class="item">
          <p class="msg-1">결선 진출 16팀, 2일간 <b>총 10Match</b> 진행</p>
          <h4>S.U.P.E.R(Point Rule) 규정에 따라 <s>킬, 순위 포인트 합산으로 순위 산정</s></h4>
          <div class="week-date">
            <dl v-for="item in weekDate3" :key="item.key">
              <dt>
                <img :src="item.title" :alt="item.key">
              </dt>
              <dd v-html="item.day" />
            </dl>
          </div>
        </div>
      </PItem>
    </div>

    <div class="btn-go-apply">
      <button @click="goApply">참가 신청 바로가기</button>
    </div>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'PubgMatchGroup',
  mixins: [gtag],
  data() {
    return {
      weekDate1: [
        '8월 21일 <s>(월)</s>',
        '8월 28일 <s>(월)</s>',
        '9월 4일 <s>(월)</s>',
      ],
      weekDate2: [
        '8월 22일 <s>(화)</s>',
        '8월 29일 <s>(화)</s>',
        '9월 5일 <s>(화)</s>',
      ],
      weekDate3: [
        { key: 'GRAND-FINAL-DAY1', title: '/img/pages/pls202303/grand_final_day1.svg', day: '9월 11일 (월)' },
        { key: 'GRAND-FINAL-DAY2', title: '/img/pages/pls202303/grand_final_day2.svg', day: '9월 12일 (화)' },
      ],
    };
  },
  methods: {
    goApply() {
      this.trackEvent(`${this.$route.meta.leagueCode}_landing_challenge_02`, 'click', `${this.upRouteGameId}`);
      this.$router.push(`/b/pubg${this.$route.params.league ? `/${this.$route.params.league}` : ''}?page=arena`);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[pubg-match-group-23s3] { .rel; .pt(56); .pb(196); .font-g; .c(#fff); .bgc(#141a24); .cover('@{pubg-intro}/bg_match_section.jpg'); .bg-xc; .bg-y(bottom); .bg-s(1060, auto); /* .bg-s(1040,auto); */
  &:before { .cnt; .abs; .lt(0, 0); .r(0); .h(1); .max-w(335); .mh-c; .bg('@{pubg-intro}/bg_divide.svg'); .bg-xc; .bg-s(auto, 100%); }
  h2, h3 { .max-w(335); .mh-c; .ls(-2); .tc;}
  h2 { .mb(32); .fs(28, 40); .no-repeat('@{pubg-intro}/bg_match_section_title.svg'); .bg-xc; }
  s { .block; .tc; }

  .article-group {
    + .article-group { .mt(24); }
  }
  article { .ani-base;
    .intro-up(1, { .ani-on; });
    h3 { .medium; .fs(22, 40); .mb(3); }
    .item { .max-w(335); .mh-c; .p(22, 0, 17, 0); .bgc(rgba(118, 87, 147, 0.2)); .font-spoqa; .tc;
      p { .medium;
        &.msg-1 { .mt(8); .fs(14); }
        &.msg-2 { .mt(8); .fs(12, 16); .c(#799efa); }
      }
      h4 { .font-spoqa; .bold; .mt(8); .fs(22); .c(#f6ea46); }
      .week-date { .mt(14); .flex; .max-w(320); .mh-c; .font-spoqa; .tc;
        dl { flex: 1;
          + dl { .ml(5); }
        }
        dt { .fs(12, 32); .bgc(rgba(179, 71, 230, .2));
          img { .wh(47, 20); }
        }
        dd { .flex-center; .gap(4); .mt(2); .h(52); .bold; .fs(14, 16); .bgc(rgba(106, 103, 255, 0.5));
          s { .medium; }
        }
      }
    }
    &.match-1 h3 { background: linear-gradient(101.01deg, #b548e8 0%, #8027aa 100%); }
    &.match-2 h3 { background: linear-gradient(101.01deg, #841db4 0%, #4b0d67 100%); }
    &.match-3 { .pb(0);
      h3 { background: linear-gradient(101.01deg, #ff3d00 0%, #7010d0 100%); }
      .item { .p(32, 0);
        h4 { .fs(18, 24); }
        .week-date { .flex; .flex-column; .items-center; .gap(16);
          dl { .ml(0); .w(200);
            dt { .flex-center;
              img { .wh(auto, 100%); }
            }
            dd { .h(52); .fs(16, 16); .bold; }
          }
        }
        p.msg-1 b { .c(#799efa); .medium; }
        p.msg-2 { .mt(14); }
      }
    }
    + article { .mt(24); }
  }
  .btn-go-apply { .mt(40); .tc; filter: grayscale(0%);
    animation: loop-grayscale 2s infinite;
    button { .wh(359, 84); .no-repeat('@{pubg-intro}/btn_goto.png'); .fs(0, 0); .c(transparent); }
  }

  @media (@tl-up) {
    .pt(66); .pb(317); .bg-s(1600, auto);
    &:before { .t(10); .max-w(908); }
    h2, h3 { .max-w(908); .mh-c; .ls(-2); .tc;}
    h2 { .mb(58); .fs(40, 60); .bg('@{pubg-intro}/tl/bg_match_section_title.svg'); }

    .article-group { .max-w(908); .mh-c; .flex;
      + .article-group { .mt(32); }
    }
    article { flex: 1; .rel;
      h3 { .fs(32, 56); .mb(8); }
      .item { .max-w(inherit); .p(40, 0, 36, 0); .min-h(calc(100% - 65px));
        p {
          &.msg-1 { .mt(0); .fs(16); }
          &.msg-2 { .mt(10); .fs(14, 20); .min-h(40); }
        }
        h4 { .mt(12); .fs(28); }
        .week-date { .mt(24); .flex; .max-w(380);
          dt { .lh(36);
            img { .wh(auto, 20); }
          }
          dd { .h(64); .fs(16, 20); }
        }
      }
      &.match-3 { .pb(0);
        s { .ib; .vat; }
        .item { .max-w(908); .p(40, 0);
          h4 { .fs(24, 28); }
          .week-date { .mt(36); .flex-row; .justify-center;
            dl { .w(320);
              dt { .h(64); }
              dd { .h(84); .fs(20, 22); .bold;
                em { .mt(10); .fs(24, 22); }
              }
            }
          }
        }
      }
      + article { .rel; .mt(0); .ml(68);
        &:before { .cnt; .abs; .lt(-45, 50%); .t-yc_; .wh(22, 54); .bg-s(100%, 100%); .no-repeat('@{pubg-intro}/bg_arrow_r.svg'); }
      }
    }

    .btn-go-apply { .mt(60);
      button { .wh(556, 153); .bg('@{pubg-intro}/tl/btn_goto.png'); }
    }
  }
  @media (@ds-up) {
    .pt(80); .pb(395); .bg-s(1920, auto);
    &:before { .max-w(@screen-ds-min); }
    h2, h3 { .max-w(@screen-ds-min); }
    h2 { .mb(48); .fs(48, 80); .bg('@{pubg-intro}/ds/bg_match_section_title.svg'); }

    .article-group { .max-w(1279);
      + .article-group { .mt(40); }
    }
    article {
      h3 { .fs(36, 76); }
      .item { .p(54, 0, 40); .min-h(unset);
        s { .ib; }
      ;
        p {
          &.msg-1 { .fs(24, 20); }
          &.msg-2 { .mt(9); .fs(16, 24); }
        }
        h4 { .mt(20); .fs(36); }
        .week-date { .mt(24); .flex; .max-w(488);
          dt { .lh(40);
            img { .wh(auto, 22); }
          }
          dd { .h(68); .fs(18, 22); }
        }
      }
      &.match-3 { .pb(0);
        s { .ib; .vat; }
        .item { .max-w(@screen-ds-min); .p(56, 0);
          h4 { .fs(32, 20); .mt(24); }
          .week-date { .w(1156); .gap(16); .mt(32);
            dl { .w(360);
              &:nth-child(3), &:nth-child(5) { .ml(12); }
              dt { .h(74);
                img { .h(56); }
              }
              dd { .fs(24, 22); .h(96); }
            }
          }
          p.msg-2 { .mt(24); .fs(24, 24); }
        }
      }
      + article { .ml(80);
        &:before { .l(-51); .wh(32, 77); }
      }
    }
  }
}
</style>
